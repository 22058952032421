<template>
  <AntInput
    :value="props.value || ''"
    @update:value="(val) => emit('update:value', val)"
    :disabled="formState?.disabled || disabled"
    :placeholder="placeholder"
    class="gum-text-input"
  />
</template>

<script setup lang="ts">
import AntInput from 'ant-design-vue/es/input'
import { useFormState } from './global-state'

const formState = useFormState()

const props = defineProps<{
  value: string | null | undefined
  placeholder?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string): void
}>()
</script>
